<template>
    <div class="page" ref="business">
        <nav class="navbar col-12 col-md-10 col-lg-9 m-0 shadow-none mb-0 mb-md-3 mx-auto">
            <div class="container p-0">
                <router-link
                    class="navbar-brand p-0 ml-auto mr-0"
                    rel="follow"
                    :to="{ name: 'home', meta: { scrollTop: 0 } }"
                >
                    <span class="text-right font-italic text-muted" style="margin-right: -15px">
                        <small>diseñado por</small>
                    </span>
                    <img
                        src="@/assets/icons/ac-concept-logo-green50.png"
                        width="auto"
                        alt="Logotipo de AlertaCoches.es"
                    />
                </router-link>
            </div>
        </nav>

        <div class="container mb-2 pb-sm-5" id="heading-business-section">
            <div class="row">
                <div class="col-12 heading-business-section p-0">
                    <div class="upper-section col-12 col-md-10" id="upper-section">
                        <div class="content">
                            <div class="background-image">
                                <img
                                    :src="`${this.customer.backgroundUrl}`"
                                    :alt="`Imagen de fondo de ${this.customer.visibleName}`"
                                    loading="eager"
                                />
                            </div>
                            <div class="logo">
                                <img
                                    :src="`${this.customer.logoUrl}`"
                                    :alt="`Logo de ${this.customer.visibleName}`"
                                    loading="eager"
                                />
                            </div>
                            <div class="texts">
                                <div class="motto">
                                    <span
                                        v-html="this.customer.motto"
                                        :style="`background-color: ${this.customer.tertiaryColor}; color: ${this.customer.tertiaryFontColor}; border-color: ${this.customer.tertiaryFontColor}`"
                                    ></span>
                                    <img
                                        src="https://imagedelivery.net/F646Wun-eua00pA0NmkORQ/9fc9fa74-7692-4571-58ca-c95e623b0a00/thumbnail"
                                        loading="eager"
                                        :alt="`Flecha indicando el eslogan de ${this.customer.visibleName}`"
                                    />
                                </div>
                                <div class="business-short-desc" id="business-short-desc">
                                    <h1>{{ this.customer.shortDescription }}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-section col-12 p-4 p-md-5 pt-2 pb-2 mx-auto" id="button-section">
                        <div class="row col-12 col-xl-11 mx-auto">
                            <div id="rrss" class="rrss col-12 col-md-4 p-0 pl-2 pl-md-5 mb-4">
                                <a
                                    :href="`${this.customer.youtubeLink}`"
                                    target="_blank"
                                    rel="nofollow"
                                    :style="`color: ${this.customer.tertiaryFontColor}!important`"
                                    v-if="this.customer.youtubeLink"
                                >
                                    <v-icon icon="mdi mdi-youtube"></v-icon>
                                </a>
                                <a
                                    :href="`${this.customer.instagramLink}`"
                                    target="_blank"
                                    rel="nofollow"
                                    :style="`color: ${this.customer.tertiaryFontColor}!important`"
                                    v-if="this.customer.instagramLink"
                                >
                                    <v-icon icon="mdi mdi-instagram"></v-icon>
                                </a>
                                <a
                                    :href="`${this.customer.linkedInLink}`"
                                    target="_blank"
                                    rel="nofollow"
                                    :style="`color: ${this.customer.tertiaryFontColor}!important`"
                                    v-if="this.customer.linkedInLink"
                                >
                                    <v-icon icon="mdi mdi-linkedin"></v-icon>
                                </a>
                                <a
                                    :href="`${this.customer.facebookLink}`"
                                    target="_blank"
                                    rel="nofollow"
                                    :style="`color: ${this.customer.tertiaryFontColor}!important`"
                                    v-if="this.customer.facebookLink"
                                >
                                    <v-icon icon="mdi mdi-facebook"></v-icon>
                                </a>
                            </div>
                            <div
                                id="cta-section"
                                class="cta-section col-12 col-md-8 p-0 mb-4"
                                :style="`color: ${this.customer.primaryFontColor}`"
                            >
                                <a
                                    :href="`https://wa.me/${this.customer.phone}`"
                                    target="_blank"
                                    rel="nofollow"
                                    v-if="this.customer.phone"
                                    :style="`background-color: ${this.customer.primaryColor}`"
                                    :aria-label="`Enviar un mensaje de WhatsApp a ${this.customer.visibleName}`"
                                >
                                    <v-icon icon="mdi mdi-whatsapp"></v-icon>
                                </a>
                                <a
                                    :href="`tel:${this.customer.phone}`"
                                    target="_blank"
                                    rel="nofollow"
                                    v-if="this.customer.phone"
                                    :style="`background-color: ${this.customer.primaryColor}`"
                                    :area-label="`Llamar a ${this.customer.visibleName}`"
                                >
                                    <span>{{ this.customer.phone }}</span>
                                </a>
                                <a
                                    :href="`${this.customer.website}`"
                                    target="_blank"
                                    rel="nofollow"
                                    v-if="this.customer.website"
                                    :style="`background-color: ${this.customer.primaryColor}`"
                                    :area-label="`Visitar la web de ${this.customer.visibleName}`"
                                >
                                    <span>{{ this.customer.website.replace('https://', '') }}</span>
                                </a>
                                <a
                                    :href="`mailto:${this.customer.email}`"
                                    target="_blank"
                                    rel="nofollow"
                                    v-if="this.customer.email"
                                    :style="`background-color: ${this.customer.primaryColor}`"
                                    :area-label="`Enviar un correo a ${this.customer.visibleName}`"
                                >
                                    <span>{{ this.customer.email }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mb-5 mb-sm-0 mb-md-5 mt-sm-5" id="info-business-section">
            <div class="row">
                <div class="col-12 col-md-10 business-info-section p-0 d-flex flex-column mx-auto">
                    <h2 class="pl-3 p-sm-0" :style="`color: ${this.customer.primaryColor}`">
                        Todo lo que necesitas saber
                    </h2>
                    <div class="upper-section col-12 mb-5 mx-auto">
                        <div class="content">
                            <div class="text">
                                <h3 :style="`color: ${this.customer.primaryColor}`" class="mb-2">
                                    Sobre {{ this.customer.visibleName }}
                                </h3>
                                <p>
                                    {{ this.customer.description }}
                                </p>
                            </div>
                            <div class="col-lg-6 d-none d-lg-block float-right p-4" v-if="this.customer.address">
                                <iframe
                                    :title="`Mapa de la localización de ${this.customer.visibleName}`"
                                    width="100%"
                                    height="100%"
                                    loading="extra-lazy"
                                    allowfullscreen="false"
                                    referrerpolicy="no-referrer-when-downgrade"
                                    :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyB-x6G3Vny0IiTcKJQ0ZKB_y5_cWxOO7ps&amp;q=${this.customer.address}`"
                                    style="border: 0px"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-10 mb-4 mx-auto d-md-none" v-if="this.customer.address">
                        <iframe
                            :title="`Mapa de la localización de ${this.customer.visibleName}`"
                            width="100%"
                            height="100%"
                            loading="extra-lazy"
                            allowfullscreen="false"
                            referrerpolicy="no-referrer-when-downgrade"
                            :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyB-x6G3Vny0IiTcKJQ0ZKB_y5_cWxOO7ps&amp;q=${this.customer.address}`"
                            style="border: 0px"
                        ></iframe>
                    </div>
                    <div
                        class="container d-flex d-sm-none justify-content-center d-md-none"
                        @click="scrollToContactForm('contact')"
                    >
                        <button
                            class="btn cta-button"
                            :style="`background-color: ${this.customer.secondaryColor}; color: ${this.customer.secondaryFontColor}`"
                        >
                            <span>Contactar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="container d-none d-sm-flex d-lg-none justify-content-center"
            @click="scrollToContactForm('contact')"
        >
            <button
                class="btn cta-button"
                :style="`background-color: ${this.customer.secondaryColor}; color: ${this.customer.secondaryFontColor}`"
            >
                <span>Contactar</span>
            </button>
        </div>
        <div class="container text-left mb-0" id="customer_stock">
            <div class="row pt-0 col-12 col-xl-10 mx-auto">
                <div class="col-12 col-md-10 col-xl-8 mx-sm-auto p-xl-0">
                    <h2 class="d-none d-lg-block" :style="`color: ${this.customer.primaryColor}`">
                        Nuestros coches de ocasión
                    </h2>
                    <div class="row d-flex justify-content-left" id="results_area">
                        <template v-if="(typeof ads.items == 'undefined' || ads.items.length == 0) && ads.loading">
                            <SkeletonAdCard v-for="i in 20" :key="i" />
                        </template>
                        <template v-else>
                            <AdCard
                                v-for="ad in ads.items"
                                :key="ad.id"
                                :card="ad"
                                :brandStyle="brandStyle"
                                :isBusinessPage="true"
                            />
                            <template v-if="!ads.initialSearch && ads.loading">
                                <div class="m-auto col-8 col-md-6 col-xl-1 mb-5">
                                    <LoadingSpinner />
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div
                    class="col-12 col-sm-9 col-md-10 col-xl-4 pt-0 d-flex mx-sm-auto justify-content-center p-xl-0 pl-5 pt-6"
                    id="contact"
                >
                    <div class="row">
                        <div class="col-12 p-xl-0 pl-4">
                            <div class="contact-box position-sticky-top">
                                <div class="contact-box-title">
                                    <h3 :style="`color: ${this.customer.primaryColor}`">¿Hablamos?</h3>
                                </div>
                                <div class="contact-box-text">
                                    <p>
                                        Si tienes alguna duda o necesitás más información sobre nuestros coches y
                                        servicios, estamos aquí para ayudarte.
                                    </p>
                                </div>
                                <v-form @submit.prevent id="contact-form" ref="contactForm">
                                    <v-text-field
                                        v-model="name"
                                        :rules="nameRules"
                                        label="Nombre *"
                                        variant="outlined"
                                        density="compact"
                                        clearable
                                        rounded
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        label="Correo electrónico *"
                                        variant="outlined"
                                        density="compact"
                                        clearable
                                        rounded
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="phone"
                                        :rules="phoneNumberRules"
                                        label="Teléfono *"
                                        variant="outlined"
                                        density="compact"
                                        clearable
                                        rounded
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="city"
                                        label="Ciudad"
                                        variant="outlined"
                                        density="compact"
                                        clearable
                                        rounded
                                    ></v-text-field>
                                    <v-textarea
                                        v-model="message"
                                        :rules="messageRules"
                                        label="Mensaje *"
                                        variant="outlined"
                                        density="compact"
                                        rounded="xl"
                                        clearable
                                    ></v-textarea>
                                    <v-checkbox v-model="terms" :rules="termsRules" class="text-left">
                                        <template v-slot:label>
                                            <p class="checkbox-text">
                                                He leído y acepto el
                                                <a
                                                    target="_blank"
                                                    href="https://about.alertacoches.es/aviso-legal"
                                                    @click.stop
                                                >
                                                    Aviso legal
                                                </a>
                                                , la
                                                <a
                                                    target="_blank"
                                                    href="https://about.alertacoches.es/politica-privacidad"
                                                    @click.stop
                                                >
                                                    Política de privacidad
                                                </a>
                                                y la
                                                <a
                                                    target="_blank"
                                                    href="https://about.alertacoches.es/politica-cookies"
                                                    @click.stop
                                                >
                                                    Política de cookies
                                                </a>
                                            </p>
                                        </template>
                                    </v-checkbox>
                                    <v-btn
                                        type="submit"
                                        block
                                        class="mb-4"
                                        :loading="contactInProgress"
                                        @click="sendContactForm"
                                        :style="`background-color: ${this.customer.primaryColor}; color: ${this.customer.primaryFontColor}`"
                                        :disabled="!isFilled() || contactInProgress"
                                        density="compact"
                                        rounded="pill"
                                    >
                                        Enviar
                                    </v-btn>
                                </v-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="container d-none d-lg-flex justify-content-center d-md-none"
                @click="scrollToContactForm('customer_stock')"
            >
                <button
                    class="btn cta-button"
                    :style="`background-color: ${this.customer.secondaryColor}; color: ${this.customer.secondaryFontColor}`"
                    variant="plain"
                >
                    <span>Contactar</span>
                </button>
            </div>
        </div>
        <CustomerReviews :customer="this.customer" :brandStyle="this.brandStyle" v-if="this.customer.slug" />
        <Footer />
    </div>
</template>

<script>
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';
import { validate } from '@/infrastructure/services/validator';
import { searchAnuncio } from '@/application/searchAnuncio/searchAnuncio';
import { getPageConfiguration } from '@/application/business/getPageConfiguration';
import { sendContactMessage } from '@/application/business/sendContactMessage';
import AdCard from '@/components/cards/AdCard.vue';
import SkeletonAdCard from '@/components/cards/SkeletonAdCard.vue';
import CustomerReviews from '@/components/structural/CustomerReviews.vue';
import Footer from '@/components/structural/Footer.vue';
import { mapState } from 'vuex';

export default {
    components: {
        Footer,
        AdCard,
        SkeletonAdCard,
        CustomerReviews,
    },
    data() {
        return {
            customer: reactive({
                visibleName: null,
                shortDescription: null,
                description: null,
                address: null,
                phone: null,
                email: null,
                website: null,
                motto: null,
                slug: null,
                logoUrl: null,
                backgroundUrl: null,
                primaryColor: null,
                secondaryColor: null,
                tertiaryColor: null,
                primaryFontColor: null,
                secondaryFontColor: null,
                tertiaryFontColor: null,
                youtubeLink: null,
                facebookLink: null,
                instagramLink: null,
                linkedInLink: null,
            }),
            brandStyle: {
                mobileColumnSize: 6,
                tabletColumnSize: 6,
                desktopColumnSize: 4,
                titleColor: '#008AFF',
                priceColor: '#E93C61',
                locationColor: '#000',
                locationIconColor: '#E93C61',
                fontFamily: 'Montserrat',
                titleFontSize: '1.1em',
                priceFontSize: '1.2em',
                locationFontSize: '.9em',
                priceFontWeight: '400',
            },
            name: '',
            email: '',
            phone: '',
            city: '',
            message: '',
            terms: false,
            contactInProgress: false,
            nameRules: validate.name,
            emailRules: validate.email,
            phoneNumberRules: validate.phoneNumber,
            cityRules: validate.name,
            messageRules: validate.message,
            termsRules: validate.termsContact,
        };
    },
    computed: {
        ...mapState(['ads']),
    },
    mounted() {
        gtag('event', 'ac_customer_page_visit', {
            customer: this.customer.slug,
        });
    },
    async created() {
        this.customer = await getPageConfiguration(this.$route.params.businessType, this.$route.params.slug);
        const siteData = reactive({
            title: `${this.customer.visibleName} | ${this.customer.shortDescription} | ${this.customer.motto} | AlertaCoches`,
            description: `${this.customer.description}`,
            ogTitle: `${this.customer.visibleName} | ${this.customer.shortDescription}`,
            ogDescription: `${this.customer.description}`,
            ogImage: `${this.customer.logoUrl}`,
            keywords: `${this.customer.visibleName}, ${this.customer.shortDescription}, coches, segunda mano, coches de segunda mano, coches baratos, coches de ocasión, coches usados, compraventa de coches, compraventa`,
        });

        useHead({
            title: computed(() => `${this.customer.visibleName} | ${this.customer.shortDescription} | AlertaCoches`),
            meta: [
                { name: 'description', content: computed(() => siteData.description) },
                { name: 'keywords', content: computed(() => siteData.keywords) },
                { name: 'og:title', content: computed(() => siteData.ogTitle) },
                { name: 'og:description', content: computed(() => siteData.ogDescription) },
                { name: 'og:image', content: computed(() => siteData.ogImage) },
                { name: 'og:image:secure_url', content: computed(() => siteData.ogImage) },
                { name: 'og:url', content: window.location.href },
                { name: 'twitter:title', content: computed(() => siteData.ogTitle) },
                { name: 'twitter:description', content: computed(() => siteData.ogDescription) },
                { name: 'twitter:image', content: computed(() => siteData.ogImage) },
            ],
        });
        await searchAnuncio({
            page: 1,
            perpage: 50,
            sourceId: this.customer.sourceId,
        });
    },
    methods: {
        async sendContactForm() {
            if (this.$refs.contactForm.validate()) {
                this.contactInProgress = true;

                const response = await sendContactMessage({
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    city: this.city,
                    message: this.message,
                    customerSlug: this.customer.slug,
                });

                if (response) {
                    gtag('event', 'ac_contact_message', {
                        customer: this.customer.slug,
                    });
                    this.$refs.contactForm.reset();
                }

                this.contactInProgress = false;
            }
        },
        scrollToContactForm(id = 'contact') {
            let contactForm = document.getElementById(id);
            if (contactForm) {
                contactForm.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }
        },
        isFilled() {
            return this.name && this.email && this.phone && this.message && this.terms;
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.heading-business-section {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    .upper-section {
        position: relative;
        height: 370px;
        border-radius: 20px;
        margin: 0 auto;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            120deg,
            rgba(255, 255, 255, 0.7021183473389356) 5%,
            rgba(238, 238, 238, 0.7) 44%,
            rgba(221, 221, 221, 0.7) 92%
        );
        background: -webkit-linear-gradient(
            120deg,
            rgba(255, 255, 255, 0.7021183473389356) 5%,
            rgba(238, 238, 238, 0.7) 44%,
            rgba(221, 221, 221, 0.7) 92%
        );
        background: linear-gradient(
            120deg,
            rgba(255, 255, 255, 0.7021183473389356) 5%,
            rgba(238, 238, 238, 0.7) 44%,
            rgba(221, 221, 221, 0.7) 92%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dddddd",GradientType=1);
        .content {
            width: 100%;
            height: 100%;
            .background-image {
                z-index: 100;
                width: 100%;
                img {
                    margin: 0 auto;
                    z-index: 0;
                    width: calc(100% + 100px);
                    margin-top: 105px;
                    margin-left: -50px;
                }
            }
            .logo {
                position: absolute;
                float: left;
                bottom: 0;
                z-index: 100;
                border-radius: $border-radius-deep;
                padding: 10px;
                margin-bottom: 15px;
                margin-left: 5px;
                img {
                    width: 140px;
                    height: auto;
                }
            }
            .texts {
                position: absolute;
                float: left;
                z-index: 10;
                bottom: 0;
                height: 100%;
                width: 100%;
                text-align: center;
                .motto {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 45px;
                    font-size: 1.05em;
                    span {
                        width: fit-content;
                        margin-left: 20px;
                        background: rgb(255, 255, 255);
                        background: -moz-linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0.7021183473389356) 5%,
                            rgba(238, 238, 238, 0.7) 44%,
                            rgba(221, 221, 221, 0.7) 92%
                        );
                        background: -webkit-linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0.7021183473389356) 5%,
                            rgba(238, 238, 238, 0.7) 44%,
                            rgba(221, 221, 221, 0.7) 92%
                        );
                        background: linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0.7021183473389356) 5%,
                            rgba(238, 238, 238, 0.7) 44%,
                            rgba(221, 221, 221, 0.7) 92%
                        );
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dddddd",GradientType=1);
                        padding: 10px 26px 10px 16px;
                        border-radius: 25px;
                        border: 1px solid $gray-light;
                    }
                    img {
                        position: relative;
                        float: right;
                        height: 80px;
                        width: 80px;
                        margin-left: -40px;
                        margin-top: -40px;
                    }
                }
                .business-short-desc {
                    position: absolute;
                    float: right;
                    z-index: 10;
                    bottom: 0;
                    right: 0;
                    width: 180px;
                    padding: 0px 15px;
                    margin-bottom: 15px;
                    h1 {
                        color: #666;
                        text-align: right;
                        font-size: 1em;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 0.2px;
                    }
                }
            }
        }
    }
    .button-section {
        position: relative;
        z-index: 5;
        float: left;
        right: 0;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        .rrss {
            padding: 5px;
            text-align: left;
            font-size: 1.2em;
            font-weight: bold;
            padding: 5px 15px;
            a {
                font-size: 1.2em;
                margin-left: 12px;
            }
        }
        .cta-section {
            text-align: right;
            a {
                font-family: 'Montserrat', sans-serif;
                min-width: 30px;
                color: $white !important;
                text-align: center;
                margin: 4px;
                border-radius: 14px;
                padding: 4px 4px 6px 4px;
                border: none;
                i {
                    height: 40px;
                    width: 20px;
                }
                span {
                    padding: 5px;
                    font-size: 0.8em;
                    font-weight: bold;
                    text-transform: lowercase !important;
                    letter-spacing: 0.1px;
                }
                .v-btn--variant-plain {
                    opacity: 1;
                }
            }
        }
    }
}
.business-info-section {
    position: relative;
    overflow: hidden;
    .upper-section {
        position: relative;
        float: left;
        height: 210px;
        background-color: #f5f7fb;
        color: #444;
        overflow: hidden;
        line-height: 1.05em;
        .content {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .text {
                position: absolute;
                float: left;
                z-index: 10;
                top: 0;
                width: 100%;
                padding: 30px 35px;
                text-align: left;
                align-items: center;
                margin: auto;
                color: #666;
                p {
                    font-size: 0.8em;
                }
            }
        }
    }
    iframe {
        height: 240px;
    }
    .button-section {
        position: relative;
        z-index: 10;
        float: left;
        right: 0;
        height: 210px;
        margin-top: 15px;
        border: 1px solid $gray-light;
        overflow: hidden;
    }
}
h2,
h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}
h2 {
    font-size: 1.1em !important;
    margin-bottom: 15px;
}
h3 {
    font-size: 1.1em !important;
}
.cta-button {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    text-transform: uppercase;
    padding: 10px 40px;
    margin-top: 5px;
    border-radius: 30px !important;
    span {
        padding: 5px 10px 5px 10px;
        font-size: 1.2em;
        font-weight: bold !important;
        letter-spacing: 0.1px;
    }
    .v-btn--variant-plain {
        opacity: 1;
    }
}
.contact-box {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 36;
    width: 100%;
    padding: 40px 20px;
    border-radius: 30px;
    background-color: #f5f7fb;
    color: #222;
    overflow-x: hidden;
    .contact-box-title {
        font-size: 1em !important;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }
    p {
        font-size: 0.8em;
        line-height: 14px;
        margin-bottom: 20px;
    }
    .contact-box-text,
    .checkbox-text {
        font-size: 0.9em !important;
        line-height: 14px;
    }
    button {
        padding: 20px 40px;
        color: $white;
        font-weight: bold;
        cursor: pointer;
        span {
            font-size: 1.4em;
            letter-spacing: 0.1px;
        }
    }
    .v-input--density-compact {
        .v-field__outline {
            padding: -20px 0px;
        }
    }
    .v-field__outline,
    .v-selection-control i {
        color: rgba(0, 136, 255) !important;
    }
    .v-selection-control i {
        margin-top: -20px;
    }
    .v-field {
        font-size: 0.9em;
        padding: 2px 0px;
        .v-field__field {
            font-size: 0.9em;
            .v-label {
                font-size: 0.95em;
            }
        }
        .v-label .v-label--clickable {
            p {
                margin-bottom: 0px;
            }
        }
    }
    .v-messages__message,
    .v-input__details {
        margin-bottom: 5px;
    }
}

//sm
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .heading-business-section {
        .upper-section {
            height: 380px;
            width: 82%;
            .content {
                .background-image {
                    img {
                        width: calc(100% + 200px);
                        margin-top: 70px;
                        margin-left: -100px;
                    }
                }
                .logo {
                    margin-left: 25px;
                    img {
                        width: 150px;
                    }
                }
                .texts {
                    .motto {
                        margin-top: 35px;
                        margin-left: 20px;
                        font-size: 1.35em;
                        border-radius: 35px;
                        border-width: 1px;
                        span {
                            height: 48px;
                            padding: 6px 30px 5px 16px;
                        }
                        img {
                            margin-top: -30px;
                            margin-left: -38px;
                            height: 80px;
                            width: 80px;
                        }
                    }
                    .business-short-desc {
                        margin-right: 25px;
                        h1 {
                            font-size: 0.95em;
                        }
                    }
                }
            }
        }
        .button-section {
            .rrss {
                a {
                    font-size: 1em;
                    margin-left: 20px;
                }
            }
            .cta-section {
                padding-right: 20px !important;
                a:first-child {
                    padding: 4px 2px 8px 2px;
                    i {
                        font-size: 1.7em;
                    }
                }
                a {
                    margin-left: 5px;
                    padding: 4px 12px 8px 10px;
                    border-radius: 20px;
                    span {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
    .business-info-section {
        iframe {
            height: 320px;
            border-radius: 25px;
        }
        .upper-section {
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            margin: 0 auto;
            .content {
                .text {
                    padding: 30px 50px;
                    p {
                        font-size: 0.95em;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    h2 {
        font-size: 1.2em !important;
        margin-left: 15px;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 1.1em !important;
    }
    .cta-button {
        margin-bottom: 25px;
    }
    .contact-box {
        .contact-box-title {
            font-size: 1em !important;
        }
        p {
            font-size: 1em;
            line-height: 16px;
            margin-bottom: 30px;
        }
        .contact-box-text,
        .checkbox-text {
            font-size: 0.9em !important;
            line-height: 18px;
        }
        button {
            padding: 22px 40px;
            span {
                font-size: 1.3em;
            }
        }
        .v-selection-control i {
            margin-top: -45px;
        }
        .v-field {
            font-size: 0.95em;
        }
    }
}
//md
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .heading-business-section {
        .upper-section {
            height: 540px;
            width: 84%;
            .content {
                .background-image {
                    img {
                        width: calc(100% + 280px);
                        margin-top: 90px;
                        margin-left: -140px;
                    }
                }
                .logo {
                    z-index: 200;
                    margin-bottom: 20px;
                    margin-left: 30px;
                    img {
                        width: 220px;
                        height: auto;
                    }
                }
                .texts {
                    .motto {
                        font-size: 1.9em;
                        margin-top: 45px;
                        margin-left: 20px;
                        span {
                            height: 65px;
                            padding: 8px 36px 10px 26px;
                            border-radius: 45px;
                        }
                        img {
                            margin-top: -40px;
                            margin-left: -48px;
                            height: 100px;
                            width: 100px;
                        }
                    }
                    .business-short-desc {
                        width: 210px;
                        margin-bottom: 20px;
                        margin-right: 40px;
                        h1 {
                            font-size: 1.2em;
                            letter-spacing: 0.2px;
                        }
                    }
                }
            }
        }
        .button-section {
            .rrss {
                a {
                    font-size: 1.6em;
                    margin-left: 16px;
                }
            }
            .cta-section {
                padding-top: 10px !important;
                padding-right: 10px !important;
                a:first-child {
                    padding: 12px 6px 16px 6px;
                    border-radius: 50%;
                    i {
                        font-size: 2.2em;
                    }
                }
                a {
                    margin-left: 10px;
                    padding: 12px 14px 12px 14px;
                    border-radius: 24px;
                    span {
                        font-size: 1em;
                        font-weight: bold;
                        text-transform: lowercase !important;
                        letter-spacing: 0.1px;
                    }
                    .v-btn--variant-plain {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .business-info-section {
        border-radius: 0;
        iframe {
            margin: auto;
            height: 400px;
            border-radius: 40px;
            @include box-shadow;
        }
        .upper-section {
            height: 300px;
            line-height: 1.85em;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            margin: 0 auto;
            .content {
                .text {
                    padding: 40px 70px;
                    p {
                        font-size: 1.3em;
                    }
                }
            }
        }
    }
    h2 {
        font-size: 1.6em !important;
        margin-left: 35px;
        margin-bottom: 25px;
    }
    h3 {
        font-size: 1.5em !important;
    }
    .cta-button {
        margin-bottom: 35px;
    }
    .contact-box {
        .contact-box-title {
            font-size: 0.8em !important;
        }
        p {
            font-size: 1em;
            line-height: 24px;
            margin-bottom: 40px;
        }
        .contact-box-text,
        .checkbox-text {
            font-size: 1.2em !important;
            line-height: 24px;
        }
        button {
            padding: 30px 40px;
            span {
                font-size: 1.6em;
            }
        }
        .v-field__outline,
        .v-selection-control i {
            border-radius: 35px;
        }
        .v-selection-control i {
            margin-top: -20px;
        }
        .v-field {
            font-size: 1.2em;
            .v-field__field {
                font-size: 0.9em;
                .v-label {
                    font-size: 1.05em;
                }
            }
            .v-label .v-label--clickable {
                .checkbox-text {
                    font-size: 2em !important;
                }
            }
        }
        .v-messages__message,
        .v-input__details {
            margin-bottom: 5px;
        }
    }
}
//lg
@media (min-width: $breakpoint-lg) {
    .heading-business-section {
        .upper-section {
            .content {
                .background-image {
                    img {
                        position: relative;
                        float: right;
                        width: 950px;
                        margin-top: 40px;
                        margin-right: -60px;
                    }
                }
                .logo {
                    margin-left: 25px;
                    img {
                        width: 150px;
                        height: auto;
                    }
                }
                .texts {
                    .motto {
                        justify-content: left;
                        font-size: 1.3em;
                        span {
                            padding: 5px 26px 5px 16px;
                            border-radius: 25px;
                        }
                        img {
                            position: relative;
                            float: right;
                            height: 70px;
                            width: 70px;
                            margin-left: -30px;
                            margin-top: -30px;
                        }
                    }
                    .business-short-desc {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .business-info-section {
        .upper-section {
            height: 290px;
            border-radius: 25px;
            .content {
                width: 100%;
                height: 100%;
                overflow: hidden;
                .text {
                    width: 50%;
                    p {
                        margin-top: 20px;
                        font-size: 0.95em;
                        line-height: 20px;
                    }
                }
            }
            iframe {
                display: block;
                border-radius: 25px;
            }
        }
        iframe {
            border-radius: 25px;
            display: none;
        }
        .button-section {
            position: relative;
            z-index: 10;
            float: left;
            right: 0;
            height: 210px;
            margin-top: 15px;
            border: 1px solid $gray-light;
            overflow: hidden;
        }
    }
    .contact-box {
        position: relative;
        top: 36;
        width: 100%;
        padding: 40px 20px;
        border-radius: 30px;
        background-color: #f5f7fb;
        color: #222;
        overflow-x: hidden;
        .contact-box-title {
            font-size: 1em !important;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.2px;
            margin-bottom: 20px;
        }
        p {
            font-size: 0.8em;
            line-height: 14px;
            margin-bottom: 20px;
        }
        .contact-box-text,
        .checkbox-text {
            font-size: 0.9em !important;
            line-height: 14px;
        }
        button {
            padding: 20px 40px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
            span {
                font-size: 1.4em;
                letter-spacing: 0.1px;
            }
        }
        .v-input--density-compact {
            .v-field__outline {
                padding: -20px 0px;
            }
        }
        .v-field__outline,
        .v-selection-control i {
            color: rgba(0, 136, 255) !important;
        }
        .v-selection-control i {
            margin-top: -20px;
        }
        .v-field {
            font-size: 0.9em;
            padding: 2px 0px;
            .v-field__field {
                font-size: 0.9em;
                .v-label {
                    font-size: 0.95em;
                }
            }
            .v-label .v-label--clickable {
                p {
                    margin-bottom: 0px;
                }
            }
        }
        .v-messages__message,
        .v-input__details {
            margin-bottom: 5px;
        }
    }
}
</style>
