const sourceData = [];
sourceData[0] = { name: '', logo: '' };
sourceData[1] = {
    name: 'Milanuncios',
    logo: 'https://scm-milanuncios-frontend-pro.milanuncios.com/statics/images/icons/icon-square-512x512.png',
};
sourceData[2] = { name: 'Wallapop', logo: 'https://es.wallapop.com/images/icons/ic-logo-web.svg' };
sourceData[3] = { name: '', logo: '' };
sourceData[4] = {
    name: 'Autohero',
    logo: 'https://artifacts-cdn.autohero.com/retail-sharding/public/assets/logo-sm-36a08a1910a8162a9446adf8d58e424a.png',
};
sourceData[5] = {
    name: 'Autocasion',
    logo: 'https://pbs.twimg.com/profile_images/973486446056230912/VaUQ0bO7_400x400.jpg',
};
sourceData[6] = {
    name: 'Clicars',
    logo: 'https://lh3.googleusercontent.com/-4PCBPxCy6cM/AAAAAAAAAAI/AAAAAAAAAAA/K_3aJwliIL0/s44-p-k-no-ns-nd/photo.jpg',
};
sourceData[7] = {
    name: 'AutoScout24',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/AutoScout24_Logo_2020.svg/1200px-AutoScout24_Logo_2020.svg.png',
};
sourceData[8] = {
    name: 'Coches.com',
    logo: 'https://media.glassdoor.com/sql/4009683/coches-com-squarelogo-1643105554863.png',
};
sourceData[9] = {
    name: 'Motor.es',
    logo: 'https://www.motor.es/favicon/favicon-32x32.png',
};
sourceData[10] = {
    name: 'Rattix',
    logo: 'https://api.cloudflare.com/client/v4/accounts/49a2ed278487fde302657dc0c25e14dd/images/v1/42f8e4e8-ce71-4555-10b5-0fe5b2dcc000',
};
export default { sourceData };
