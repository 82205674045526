<template>
    <template v-if="this.card.isCollabAd === true">
        <!-- 
    <template v-if="this.card.designId === 'native'">
        <div class="col-6 col-md-4 col-xl-3 mb-3">
            <div class="col-12 an-card" style="padding-top: 25px">
                <a target="_blank" :href="this.card.link" @click="registerCollabAdClick()">
                    <div class="image-container">
                        <img
                            loading="lazy"
                            :src="`/img/collab${card.images[0]}`"
                            data-image-id="0"
                            :alt="card.title"
                            :title="`${card.title}`"
                        />
                    </div>
                    <div class="caption-container pt-2 pb-2">
                        <p class="an-collab-tag">Publicidad</p>
                        <p class="an-title">{{ card.title }}</p>
                        <template v-if="card.price !== null">
                            <p class="an-paragraph">
                                {{ card.price }}
                            </p>
                        </template>
                    </div>
                </a>
            </div>
        </div>
    </template> -->
        <template v-if="this.card.designId === 'native_banner'">
            <div class="col-12 col-md-6 mb-3">
                <div class="col-12 an-banner">
                    <a target="_blank" :href="this.card.link" @click="registerCollabAdClick()">
                        <div class="image-container">
                            <img
                                loading="lazy"
                                :src="`${card.images[0]}`"
                                data-image-id="0"
                                :alt="card.title"
                                :title="`${card.title}`"
                            />
                        </div>
                        <div class="caption-container pt-2 pb-2">
                            <p class="an-collab-tag">Publicidad</p>
                            <p class="an-title">{{ card.title }}</p>
                            <template v-if="card.price !== null">
                                <p class="an-paragraph">
                                    {{ card.price }}
                                </p>
                            </template>
                        </div>
                    </a>
                </div>
            </div>
        </template>
    </template>
    <div
        class="mb-3"
        :class="[viewStyles.mobileColumnClass, viewStyles.tabletColumnClass, viewStyles.desktopColumnClass]"
        :style="`font-family: ${this.viewStyles.fontFamily}`"
        v-else
    >
        <div class="col-12 an-card">
            <v-btn
                :id="`favourite-button-${card.id}`"
                :aria-label="this.favouriteStatus ? `No me gusta este ${card.title}` : `Me gusta este ${card.title}`"
                :icon="this.favouriteStatus ? 'mdi mdi-heart' : 'mdi mdi-heart-outline'"
                density="compact"
                variant="text"
                class="favourite-button"
                :class="this.favouriteStatus ? 'added-fav' : ''"
                @click="toggleFavourite(card.id)"
                :alt="`Botón para añadir o quitar de favoritos este ${card.title}`"
            ></v-btn>
            <router-link rel="follow" :to="{ name: 'singleAd', params: { slugUrl: this.parseSlugUrl(card.slugUrl) } }">
                <div class="image-container">
                    <template v-if="!card.images[0]">
                        <img
                            loading="lazy"
                            src="@/assets/icons/no-picture.webp"
                            alt="Icono de coche: el anuncio no tiene imagenes."
                        />
                    </template>
                    <template v-else>
                        <img
                            loading="lazy"
                            :src="getCorrectSizedImage(card.images[0], card.sourceId)"
                            data-image-id="0"
                            :alt="card.title"
                            :title="`${card.title} de segunda mano`"
                        />
                    </template>
                </div>
                <div class="caption-container pt-2 pb-2">
                    <p
                        class="an-title"
                        :style="`color: ${this.viewStyles.titleColor}; font-size: ${this.viewStyles.titleFontSize} !important; line-height: 1.25em`"
                    >
                        {{ card.title }}
                    </p>
                    <p
                        class="an-price"
                        :style="`color: ${this.viewStyles.priceColor}; font-weight: ${this.viewStyles.priceFontWeight}; font-size: ${this.viewStyles.priceFontSize}`"
                    >
                        {{ $n(card.price, 'currency', { currency: card.currency }) }}
                    </p>
                    <p class="an-source" v-if="!this.isBusinessPage">{{ this.getSourceName(card.sourceId) }}</p>
                    <p
                        class="an-location"
                        :style="`color: ${this.viewStyles.locationColor}; font-size: ${this.viewStyles.locationFontSize}`"
                    >
                        <template v-if="card.location === 'online'">
                            <v-icon
                                icon="mdi-web"
                                size="small"
                                :style="`color: ${this.viewStyles.locationIconColor}`"
                            ></v-icon>
                            {{ $t('buy_online') }}
                        </template>
                        <template v-else>
                            <v-icon
                                icon="mdi-map-marker"
                                size="small"
                                :style="`color: ${this.viewStyles.locationIconColor}`"
                            ></v-icon>
                            {{ card.location.city_name }}
                        </template>
                    </p>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
import { toggleFavouriteStatus } from '@/application/favourite/toggleFavourite';

export default {
    props: {
        card: { type: Object, required: true },
        isFavourite: { type: Boolean, required: false },
        brandStyle: {
            type: Object,
            required: false,
            default: () => {
                return {
                    mobileColumnSize: 6,
                    tabletColumnSize: 4,
                    desktopColumnSize: 3,
                    titleColor: '#036666',
                    priceColor: '#ad6262',
                    locationColor: '#036666',
                    locationIconColor: '#036666',
                    fontFamily: 'Comfortaa',
                    titleFontSize: '0.9em',
                    priceFontSize: '1.05em',
                    locationFontSize: '0.8em',
                    priceFontWeight: 'normal',
                };
            },
        },
        isBusinessPage: { type: Boolean, required: false },
    },
    data() {
        return {
            showImg: true,
            favouriteStatus: this.isFavourite,
            viewStyles: {
                mobileColumnClass: 'col-6',
                tabletColumnClass: 'col-md-4',
                desktopColumnClass: 'col-xl-3',
                titleColor: '#036666',
                priceColor: '#ad6262',
                locationColor: '#036666',
                locationIconColor: '#036666',
                fontFamily: 'Comfortaa',
                titleFontSize: '0.9em',
                priceFontSize: '1.05em',
                locationFontSize: '0.8em',
                priceFontWeight: 'normal',
            },
        };
    },
    methods: {
        getSourceName(source) {
            switch (source) {
                case 1:
                    return 'Milanuncios';
                case 2:
                    return 'Wallapop';
                case 4:
                    return 'AutoHero';
                case 5:
                    return 'Autocasion';
                case 6:
                    return 'Clicars';
                case 7:
                    return 'Autoscout';
                case 8:
                    return 'Coches.com';
                case 9:
                    return 'Motor.es';
                case 10:
                    return 'Rattix';
            }
        },
        imageUrlAlt(event) {
            let picIdx = event.target.getAttribute('data-image-id');
            if (picIdx < 10) {
                picIdx++;
                event.target.setAttribute('data-image-id', picIdx);
                event.target.src = this.card.images[picIdx];
            } else {
                event.target.src = '/src/assets/icons/car.png';
                event.target.alt = 'Icono de coche: el anuncio no tiene imagenes.';
            }
        },
        getCorrectSizedImage(imageUrl, source) {
            switch (source) {
                case 2:
                    return imageUrl.replace('pictureSize=W800', 'pictureSize=W100');
                case 8:
                    if (imageUrl === 'https://images.coches.com/_vo_/default.pngp=cc_vo_medium') {
                        return 'https://images.coches.com/_vo_/default.png?p=cc_vo_medium';
                    }
                    return imageUrl + '?p=cc_vo_medium';
                default:
                    return imageUrl;
            }
        },
        parseSlugUrl(slugUrl) {
            return slugUrl.toLowerCase().replaceAll('.', '').replaceAll('/', '');
        },
        registerCollabAdClick() {
            gtag('event', 'ac_click_ad', {
                type: this.card.designId,
                customerName: this.card.customerName,
                contentId: this.card.contentId,
                make: this.card.make,
                model: this.card.model,
                province: this.card.province,
            });
        },
        toggleFavourite(id) {
            if (this.$store.getters['user/isAuthenticated']) {
                toggleFavouriteStatus(id);
                this.favouriteStatus = !this.favouriteStatus;
            } else {
                this.$store.dispatch('user/setShowLoginModal', true);
            }
        },
        setDefaultViewStyles() {
            this.viewStyles = {
                mobileColumnClass: `col-${this.brandStyle.mobileColumnSize}` || 'col-6',
                tabletColumnClass: `col-md-${this.brandStyle.tabletColumnSize}` || 'col-md-4',
                desktopColumnClass: `col-xl-${this.brandStyle.desktopColumnSize}` || 'col-xl-3',
                titleColor: this.brandStyle.titleColor || '#036666',
                priceColor: this.brandStyle.priceColor || '#ad6262',
                locationColor: this.brandStyle.locationColor || '#036666',
                locationIconColor: this.brandStyle.locationIconColor || '#036666',
                fontFamily: this.brandStyle.fontFamily || 'Comfortaa',
                titleFontSize: this.brandStyle.titleFontSize || '0.9em',
                priceFontSize: this.brandStyle.priceFontSize || '1.05em',
                locationFontSize: this.brandStyle.locationFontSize || '0.8em',
                priceFontWeight: this.brandStyle.priceFontWeight || 'normal',
            };
        },
    },
    beforeMount() {
        this.setDefaultViewStyles();
        if (this.card.isCollabAd === true) {
            gtag('event', 'ac_display_ad', {
                type: this.card.designId,
                customerName: this.card.customerName,
                contentId: this.card.contentId,
                make: this.card.make,
                model: this.card.model,
                province: this.card.province,
            });
        }
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.an-card {
    position: relative;
    border-radius: $border-radius;
    min-height: 200px;
    text-decoration: none !important;
    background-color: transparent;
    margin-top: -20px;
    .favourite-button {
        position: relative;
        z-index: 1;
        height: 35px !important;
        width: 35px !important;
        min-height: 30px !important;
        min-width: 30px !important;
        margin-bottom: -67px;
        margin-left: 5px;
        padding: 0;
        font-size: 0.8rem;
        opacity: 100%;
        cursor: pointer;
        color: $gray;
        transition: all 0.7s ease;
        background-color: $white;
    }
    .added-fav {
        color: red;
        text-shadow: $white 0 0 10px;
        transition: all 0.7s ease;
    }
    a {
        text-decoration: none;
    }
    .image-container {
        position: relative;
        background-color: $white;
        overflow: hidden;
        border-radius: $border-radius-deep;
        aspect-ratio: 1 / 1;
        transition: all 0.7s ease;
        z-index: 0;
        img {
            position: relative;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &:hover > img {
            cursor: pointer;
            transition: all 0.7s ease;
            transform: scale(1.05);
        }
    }
    .caption-container {
        overflow: hidden;
        min-height: 50px;
        text-align: left;
        background-color: transparent;
        .an-title {
            width: 100%;
            font-size: 0.9em !important;
            margin: 0px;
            margin-bottom: 2px;
            font-weight: bold;
        }
        .an-price {
            position: relative;
            float: left;
            text-align: left;
            width: 50%;
            height: 25px;
            margin-bottom: 2px;
            font-size: 1.05em;
            font-weight: bold;
        }
        .an-source {
            position: relative;
            float: right;
            text-align: center;
            width: 50%;
            height: 25px;
            margin-bottom: 2px;
            color: $secondary;
            font-size: 0.8em !important;
            font-weight: bold;
            vertical-align: bottom;
            border: 1px solid $primary;
            border-radius: $border-radius;
            padding-top: 3px;
        }
        .an-paragraph {
            width: 100%;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 0.81em !important;
            font-weight: bold;
        }
        .an-location {
            width: 100%;
            height: 25px;
            font-size: 0.8em;
            vertical-align: middle;
            padding: 5px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            i {
                padding: 0px !important;
                margin: -5px -3px 0px -3px !important;
                font-size: 1.4em;
            }
        }
        .an-collab-tag {
            width: 100%;
            height: 20px;
            font-size: 0.8em !important;
            font-weight: bold;
            color: $gray-2;
            vertical-align: middle;
            padding: 2px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.an-banner {
    position: relative;
    border-radius: $border-radius;
    min-height: 200px;
    text-decoration: none !important;
    background-color: transparent;
    a {
        text-decoration: none;
    }
    .image-container {
        background-color: $white;
        overflow: hidden;
        border-radius: $border-radius-deep;
        aspect-ratio: 1 / 0.5;
        transition: all 0.7s ease;
        img {
            position: relative;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &:hover > img {
            cursor: pointer;
        }
    }
    .caption-container {
        overflow: hidden;
        min-height: 50px;
        text-align: left;
        background-color: transparent;
        .an-title {
            width: 100%;
            font-size: 0.9em !important;
            color: $secondary;
            margin: 0px;
            margin-bottom: 2px;
            font-weight: bold;
        }
        .an-price {
            width: 100%;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 1.1em !important;
            font-weight: bold;
            img {
                position: relative;
                float: right;
                bottom: 0;
                right: 0;
                height: 22px;
                width: auto;
                border-radius: 4px;
                margin: auto 0;
            }
        }
        .an-paragraph {
            width: 100%;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 0.81em !important;
            font-weight: bold;
        }
        .an-location {
            width: 100%;
            height: 20px;
            font-size: 0.8em !important;
            color: $secondary;
            vertical-align: middle;
            padding: 2px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            img {
                width: auto;
                height: 16px;
                margin-top: -4px;
            }
        }
        .an-collab-tag {
            width: 100%;
            height: 20px;
            font-size: 0.8em !important;
            font-weight: bold;
            color: $gray-2;
            vertical-align: middle;
            padding: 2px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    //xs
    @media (max-width: $breakpoint-xs) {
    }
    //sm
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    }
    //md
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    }
    //lg
    @media (min-width: $breakpoint-md) {
    }
}
</style>
