export default {
    namespaced: true,
    state: () => {
        return {
            searchContent: {
                make: null,
                model: null,
                date: null,
                province: null,
                geartype: null,
                fueltype: null,
                minprice: null,
                maxprice: null,
                minyear: null,
                maxyear: null,
                minmileage: null,
                maxmileage: null,
                minpower: null,
                maxpower: null,
                page: null,
                perpage: null,
            },
            forceSearch: false,
        };
    },
    mutations: {
        setSearchContent(state, value) {
            state.searchContent.make = value.make;
            state.searchContent.model = value.model;
            state.searchContent.date = value.date;
            state.searchContent.province = value.province;
            state.searchContent.geartype = value.geartype;
            state.searchContent.fueltype = value.fueltype;
            state.searchContent.minprice = value.minprice;
            state.searchContent.maxprice = value.maxprice;
            state.searchContent.minyear = value.minyear;
            state.searchContent.maxyear = value.maxyear;
            state.searchContent.minmileage = value.minmileage;
            state.searchContent.maxmileage = value.maxmileage;
            state.searchContent.minpower = value.minpower;
            state.searchContent.maxpower = value.maxpower;
            state.searchContent.page = value.page;
            state.searchContent.perpage = value.perpage;
        },
        setForceSearch(state, value) {
            state.forceSearch = value;
        },
    },
    getters: {
        getSearchContent(state) {
            return state.searchContent;
        },
        isSearchForced(state) {
            return state.forceSearch;
        },
        isInitialSearch(state) {
            return (
                state.searchContent.make === null,
                state.searchContent.model === null,
                state.searchContent.date === null,
                state.searchContent.province === null,
                state.searchContent.geartype === null,
                state.searchContent.fueltype === null,
                state.searchContent.minprice === null,
                state.searchContent.maxprice === null,
                state.searchContent.minyear === null,
                state.searchContent.maxyear === null,
                state.searchContent.minmileage === null,
                state.searchContent.maxmileage === null,
                state.searchContent.minpower === null,
                state.searchContent.maxpower === null
            );
        },
    },
    actions: {
        setSearchContent({ commit }, payload) {
            commit('setSearchContent', payload);
        },
        setForceSearch({ commit }, payload) {
            commit('setForceSearch', payload);
        },
        isEqualToSearchContent({ state }, payload) {
            return (
                state.searchContent.make === payload.make &&
                state.searchContent.model === payload.model &&
                state.searchContent.date === payload.date &&
                state.searchContent.province === payload.province &&
                state.searchContent.geartype === payload.geartype &&
                state.searchContent.fueltype === payload.fueltype &&
                state.searchContent.minprice === payload.minprice &&
                state.searchContent.maxprice === payload.maxprice &&
                state.searchContent.minyear === payload.minyear &&
                state.searchContent.maxyear === payload.maxyear &&
                state.searchContent.minmileage === payload.minmileage &&
                state.searchContent.maxmileage === payload.maxmileage &&
                state.searchContent.minpower === payload.minpower &&
                state.searchContent.maxpower === payload.maxpower
            );
        },
        setDefaults({ commit }) {
            commit('setSearchContent', {
                make: null,
                model: null,
                date: null,
                province: null,
                geartype: null,
                fueltype: null,
                minprice: null,
                maxprice: null,
                minyear: null,
                maxyear: null,
                minmileage: null,
                maxmileage: null,
                minpower: null,
                maxpower: null,
                page: null,
                perpage: null,
            });
            commit('setForceSearch', false);
        },
    },
};
