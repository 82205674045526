<template v-if="this.ad && this.needCarHelpCalcUrl">
    <div class="row pb-4 mt-4">
        <div class="col-11 col-md-8 col-xl-6 embedded-service mt-5 mb-5" id="calculadora_needcarhelp">
            <h3>Revísalo con un experto</h3>
            <p>
                Obtén un informe detallado del estado mecánico, electrónico, de carrocería y legal antes de comprar.
                <b>Compra con tranquilidad.</b>
            </p>
            <div class="action">
                <a target="_blank" rel="nofollow" :href="this.needCarHelpCalcUrl" @click="registerCollabAdClick()">
                    <div class="link">
                        <span>CALCULA EL PRECIO DE LA REVISIÓN AQUÍ</span>
                    </div>
                </a>
            </div>
            <img
                src="https://needcarhelp.es/wp-content/uploads/2023/03/logo-de-needcarhelp.svg"
                alt="Calcula el coste de la revisión de tu coche"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: ['ad', 'makeList', 'modelList'],
    data() {
        return {
            needCarHelpCalcUrl: null,
            make: null,
            model: null,
            province: null,
        };
    },
    methods: {
        registerCollabAdClick() {
            gtag('event', 'ac_click_ad', {
                type: 'calculator',
                customerName: 'NeedCarHelp',
                contentId: 'calculator',
                make: this.make,
                model: this.model,
                province: this.ad.location.province,
            });
        },
        async getNeedCarHelpRefUrl() {
            this.make = await this.getShortDesc(this.ad.make, this.makeList);
            this.model = await this.getShortDesc(this.ad.model, this.modelList);
            let cp = this.ad.location.zip_code;
            let anuncio = this.ad.id;

            this.needCarHelpCalcUrl =
                'https://needcarhelp.es/calculadora/?utm_campaign=calculadora&utm_source=alertacoches&utm_medium=producto';
            if (this.make) {
                this.needCarHelpCalcUrl += `&marca=${this.make}`;
            }

            if (this.model) {
                this.needCarHelpCalcUrl += `&modelo=${this.model}`;
            }

            if (cp) {
                this.needCarHelpCalcUrl += `&cp=${cp}`;
            }

            if (anuncio) {
                this.needCarHelpCalcUrl += `&anuncioAlertaCoches=${anuncio}`;
            }
        },
        async getShortDesc(id, haystack) {
            if (!id) {
                return null;
            }
            if (!haystack) {
                return null;
            }

            let obj = haystack.find((o) => o.value_id === id);

            if (!obj) {
                return null;
            }

            return obj.short_desc;
        },
    },
    setup() {},
    async beforeMount() {
        await this.getNeedCarHelpRefUrl();
    },
    mounted() {
        gtag('event', 'ac_display_ad', {
            type: 'calculator',
            customerName: 'NeedCarHelp',
            contentId: 'calculator',
            make: this.make,
            model: this.model,
            province: this.ad.location.province,
        });
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.embedded-service {
    margin: auto;
    min-height: 140px;
    background-color: $white !important;
    border-radius: $border-radius-deep;
    font-weight: 800;
    padding: 15px;
    border: 2px dashed $gray-15;
    h3 {
        font-size: 0.9rem;
        margin-bottom: 0px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    p {
        font-size: 0.75rem;
        margin: 15px 0px;
    }
    div {
        position: relative;
        margin: 10px auto;
    }
    .action {
        margin: 15px auto;
        text-align: center;
        width: 90%;
        .link {
            width: 100%;
            padding: 10px 0px;
            background-color: #ff7802;
            border-radius: $border-radius-deep;
        }
        span {
            font-family: $secondary-font;
            text-transform: uppercase;
            color: $white;
            font-size: 0.7rem;
            height: 60px;
            width: 100%;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
    img {
        position: relative;
        float: left;
        width: 100%;
        height: 40px;
        margin: 0 auto;
    }
    .price-tag {
        position: relative;
        float: right;
        top: 0px;
        right: 0px;
        background-color: $gray-15;
        border-radius: $border-radius-deep;
        padding: 5px 10px;
        font-size: 1rem;
        font-weight: bold;
        color: $secondary;
    }
}
@media (min-width: 768px) {
    .embedded-service {
        min-height: 140px;
        h3 {
            font-size: 1.1rem;
        }
        p {
            font-size: 0.95rem;
        }
        .action {
            .link {
                padding: 15px 0px;
            }
            span {
                font-size: 1rem;
            }
        }
        img {
            height: 50px;
        }
    }
}
@media (min-width: 1200px) {
    .embedded-service {
        margin: auto 0px;
    }
}
</style>
